import { APIFindOptions, ApiQueryResponse, Folder, ApplicationLog as ApplicationLog } from '@dam/types';
import { Observable } from 'rxjs';

import { DAOService } from '../dao/dao-service';
import { DAODataSource } from './dao-data-source';

export class ApplicationLogsDAODataSource extends DAODataSource<ApplicationLog> {
    constructor(dao: DAOService<ApplicationLog>) {
        super(dao);
    }

    protected find(conditions: any, options: APIFindOptions): Observable<ApiQueryResponse<ApplicationLog>> {
        return this.dao.find(conditions, options);
    }
}
