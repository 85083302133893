import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'download-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent implements OnInit {
    form: FormGroup;
    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<DownloadDialogComponent>
    ) {
        this.form = this.fb.group({
            filename: ['', Validators.required],
            ext: '.zip'
        })
    }

    ngOnInit() {
    }

    close(accepted) {
        if (this.form && this.form.get('filename').value && accepted) {
            this.dialogRef.close({ accepted: true, filename: this.form.get('filename').value + this.form.get('ext').value })
        } else if(!accepted){
            this.dialogRef.close({ accepted: false })
        }
    }

}
