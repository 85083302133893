import * as jwtDecode from 'jwt-decode';
import { AuthAction, AuthActionTypes } from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isAuthenticated?: boolean;
  user?: any;
  token?: string;
  attemptLoginFailed?: boolean;
  error?: any;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export function checkToken(token) {
  try {
    const user = jwtDecode(authToken);
    const now = Date.now().valueOf() / 1000;
    return user && user.exp >= now;
  } catch (err) {
    return false;
  }
}

const authToken = localStorage.getItem('dam-token');
export const authInitialState: AuthState = {
  isAuthenticated: checkToken(authToken),
  user: authToken ? jwtDecode(authToken) : null,
  token: authToken
};

export function authReducer(
  state: AuthState = authInitialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess || AuthActionTypes.LoadSuccess: {
      state = {
        isAuthenticated: true,
        user: jwtDecode(action.payload),
        token: action.payload
      };
      break;
    }
    case AuthActionTypes.LoginError: {
      state = {
        isAuthenticated: false,
        error: action.payload
      };
      break;
    }
    case AuthActionTypes.Logout: {
      state = {
        isAuthenticated: false,
      };
      break;
    }
    case AuthActionTypes.ResetAuth: {
      state = { isAuthenticated: false };
      break;
    }
  }

  return state;
}
