import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemConfig } from '@dam/types';

import { DAOService } from './dao-service';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService extends DAOService<SystemConfig> {
  baseUrl = environment.baseUrl + '/api/system-config';

  constructor(protected http: HttpClient) {
    super(http);
  }

  verifySmtpSettings() {
    return this.http.get<any>(this.baseUrl + '/verifySmtpSettings').pipe(take(1));
  }
}
