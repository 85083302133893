import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemporaryLink } from '@dam/types';

import { DAOService } from './dao-service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TemporaryLinksService extends DAOService<TemporaryLink> {
    baseUrl = environment.baseUrl + '/api/temporary-links';

    constructor(protected http: HttpClient) {
        super(http);
    }

    valid(validityUUID): Observable<boolean> {
        return this.http.get<any>(`${this.baseUrl}/${validityUUID}/validate`).pipe(take(1));
    }
}
