import { APIFindOptions, ApiQueryResponse, Folder, ApplicationLog, DownloadRequest } from '@dam/types';
import { Observable } from 'rxjs';

import { DAOService } from '../dao/dao-service';
import { DAODataSource } from './dao-data-source';

export class DownloadRequestDAODataSource extends DAODataSource<DownloadRequest> {
    constructor(dao: DAOService<DownloadRequest>) {
        super(dao);
    }

    protected find(conditions: any, options: APIFindOptions): Observable<ApiQueryResponse<DownloadRequest>> {
        options.populate ? options.populate.push('createdBy') : options.populate = ['createdBy'];
        return this.dao.find(conditions, options);
    }
}
