import { Component, OnInit, Inject } from '@angular/core';
import { SystemConfigService } from '../../dao/system-config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SystemConfig, Asset } from '@dam/types';
import { AssetsService } from '../../dao/assets.service';

@Component({
  selector: 'copyright-dialog',
  templateUrl: './copyright-dialog.component.html',
  styleUrls: ['./copyright-dialog.component.css']
})
export class CopyrightDialogComponent implements OnInit {
  copyright: string;
  expireDate: string;
  constructor(
    public dialogRef: MatDialogRef<CopyrightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Asset
  ) {

  }

  ngOnInit() {
    let date = this.parseDate(this.data.copyrightExpiryDate);
    this.expireDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    if (this.data.copyrightText) {
      this.copyright = this.data.copyrightText;
    }
  }

  close(accepted) {
    this.dialogRef.close({ accepted: accepted })
  }

  private parseDate(date) {
    const parts = date.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
  }
}
