import { Injectable } from '@angular/core';
import { DAOService } from './dao-service';
import { User } from '@dam/types';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends DAOService<User> {
  baseUrl = environment.baseUrl + '/api/users';

  constructor(protected http: HttpClient) {
    super(http);
  }

  generateChangePasswordLink(userId: any): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${userId}/generateChangePasswordLink`).pipe(take(1));
  }

  changePassword(credentials: any = {}): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${credentials._id}/changePassword`,
      { oldPassword: credentials.oldPassword, newPassword: credentials.newPassword }).pipe(take(1));
  }

}
