import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'dam-field-translations-component',
  templateUrl: './field-translations-component.component.html',
  styleUrls: ['./field-translations-component.component.scss']
})
export class FieldTranslationsComponentComponent implements OnInit {
  @Input() fieldFormGroup: FormGroup;

  dataSource: {
    lang?: string,
    control?: FormControl
  }[] = [];
  displayedColumns = ['lang', 'control'];

  constructor() { }

  ngOnInit() {
    Object.keys(this.fieldFormGroup.value).forEach(lang => {
      this.dataSource.push({
        lang: lang, 
        control: this.fieldFormGroup.get(lang) as FormControl
      });
    });
  }

}
