import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatGridListModule,
  MatListModule,
  MatBadgeModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import {
  FieldTranslationsComponentComponent,
} from './components/field-translations-component/field-translations-component.component';
import { FolderSearchDialogComponent } from './components/folder-search-dialog/folder-search-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { AuthFacade } from './ngrx/auth/+state/auth.facade';
import { BasicTableModule } from './components/basic-table/basic-table.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './components/notification/notification.service';
import { SearchKeywordDialogComponent } from './components/search-keyword-dialog/search-keyword-dialog.component';
import { SearchAssetTypesDialogComponent } from './components/search-assettypes-dialog/search-assettypes-dialog.component';
import { CopyrightDialogComponent } from './components/copyright-dialog/copyright-dialog.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';

const MATERIAL_MODULES = [
  MatToolbarModule, MatButtonModule, MatTooltipModule, MatIconModule,
  MatFormFieldModule, MatInputModule, MatCardModule, MatSelectModule, MatMenuModule,
  MatCheckboxModule, MatRadioModule, MatTreeModule, MatDividerModule,
  MatTableModule, MatDialogModule, MatGridListModule, MatListModule,
  MatBadgeModule, MatSnackBarModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    FileUploadModule,
    ...MATERIAL_MODULES,
    BasicTableModule
  ],
  declarations: [
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    LogoutButtonComponent,
    FieldTranslationsComponentComponent,
    FolderSearchDialogComponent,
    NotificationComponent,
    SearchKeywordDialogComponent,
    SearchAssetTypesDialogComponent,
    CopyrightDialogComponent,
    DownloadDialogComponent

  ],
  exports: [
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FieldTranslationsComponentComponent,
    CopyrightDialogComponent,
    DownloadDialogComponent
  ],
  entryComponents: [
    FolderSearchDialogComponent,
    NotificationComponent,
    SearchKeywordDialogComponent,
    SearchAssetTypesDialogComponent,
    CopyrightDialogComponent,
    DownloadDialogComponent
  ],
  providers: [
    AuthFacade,
    NotificationService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
  ]
})
export class DamCoreModule { }
