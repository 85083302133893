import { User } from '@dam/types';

import { DAOService } from '../dao/dao-service';
import { DAODataSource } from './dao-data-source';

export class UsersDAODataSource extends DAODataSource<User> {
  constructor(dao: DAOService<User>) {
    super(dao);
  }
}
