import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { KeywordsDAODataSource } from '../../datasources/keywords-dao.datasource';
import { KeywordsService } from '../../dao/keywords.service';
import { BasicTableColumn } from '../basic-table/basic-table-column';
import { Keyword } from '@dam/types';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'dam-search-keyword-dialog',
  templateUrl: './search-keyword-dialog.component.html',
  styleUrls: ['./search-keyword-dialog.component.css']
})
export class SearchKeywordDialogComponent implements OnInit, OnDestroy {
  dataSource = new KeywordsDAODataSource(this.keywordsService);
  columns: BasicTableColumn<Keyword>[] = [
    {
      id: 'name',
      type: 'link',
      title: 'Keywords.Fields.Name',
      event: (event, keyword) => {
        event.preventDefault();
        this.close(keyword);
      },
      value: keyword => keyword.name[this.translate.currentLang || 'en']
    }
  ];

  nameControl = new FormControl();
  conditions = new BehaviorSubject<any>({});

  private filterSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SearchKeywordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string, // initial search value
    private keywordsService: KeywordsService,
    private translate: TranslateService
  ) {
    this.filterSub = this.nameControl.valueChanges.pipe(delay(500)).subscribe(value => this.changeConditions(value));
  }

  ngOnInit() {
    this.nameControl.setValue(this.data);
    this.changeConditions(this.data);
  }

  ngOnDestroy() {
    if (this.filterSub) { this.filterSub.unsubscribe(); }
  }

  close(keyword?: Keyword) {
    this.dialogRef.close(keyword);
  }

  private changeConditions(value: string) {
    const re = new RegExp(value);
    const conditions = {};
    conditions[`name.${this.translate.currentLang || 'en'}`] = { $regex: re.source, $options: 'i' };
    this.conditions.next(conditions);
  }
}
