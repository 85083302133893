import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthFacade } from '../ngrx/auth/+state/auth.facade';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authFacade.auth$.pipe(
      switchMap(auth => {
        if (auth.isAuthenticated) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${auth.token}`
            }
          });
        }
        return next.handle(req);
      })
    );
  }
}
