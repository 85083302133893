import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'dam-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.scss']
})
export class BasicCardComponent implements OnInit {
  @Input() title: string; 
  @Input() subtitle: string;
  @Input() headerButtonsTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() actionsTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
