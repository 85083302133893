import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DAOService } from '../dao/dao-service';

export class GenericDetailResolver<T> implements Resolve<T> {
  constructor(
    protected dao: DAOService<T>,
    protected router: Router,
    protected fallbackRouting: string[] = ['/']
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> {
    const id = route.paramMap.get('id');

    return this.dao.findOne(id).pipe(
      take(1),
      map(obj => {
        if (obj) {
          return obj;
        } else {
          this.router.navigate(this.fallbackRouting);
          return null;
        }
      }),
      catchError(err => {
        this.router.navigate(this.fallbackRouting);
        return of(err);
      })
    );
  }
}
