import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacade } from '../../ngrx/auth/+state/auth.facade';

@Component({
  selector: 'dam-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    locale: this.translate.currentLang || 'en'
  });

  isAuthenticated$: Observable<boolean> = this.authFacade.isAuthenticated$;

  authError$: Observable<any> = this.authFacade.error$.pipe(
    map(err => {
      if (err) {
        return err.error ? err.error.message : err.message
      } else {
        return null;
      }
    })
  );

  private authSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authFacade: AuthFacade,
  ) {
    this.authSub = this.isAuthenticated$.subscribe(isAuthenticated => isAuthenticated ? this.router.navigate(['/']) : null);
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.authSub) { this.authSub.unsubscribe(); }
  }

  login() {
    if (this.form.valid) {
      this.authFacade.login(this.form.value);
    }
  }
 
  changeLang(event) {
    this.translate.use(event.value);
  }
}
