import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { NotificationComponent } from './notification.component';

export interface SnackBarQueueItem {
    message: string;
    beingDispatched: boolean;
    configParams?: SnackBarConfigParams;
}

export interface SnackBarConfigParams {
    title?: string;
    icon?: string;
}

@Injectable()
export class NotificationService implements OnDestroy {
    private readonly snackBarQueue = new BehaviorSubject<SnackBarQueueItem[]>([]);
    private readonly ngDestroy = new Subject();
    
    snackBarQueue$ = this.snackBarQueue.asObservable();

    constructor(
        private matSnackBar: MatSnackBar
    ) {
        /* Dispatches all queued snack bars one by one */
        this.snackBarQueue$
            .pipe(
                filter(queue => queue.length > 0 && !queue[0].beingDispatched),
                tap(() => {
                    const updatedQueue = this.snackBarQueue.value;
                    updatedQueue[0].beingDispatched = true;
                    this.snackBarQueue.next(updatedQueue);
                }),
                map(queue => queue[0]),
                takeUntil(this.ngDestroy))
            .subscribe(snackBarItem => this.showSnackbar(snackBarItem.message, snackBarItem.configParams));
    }

    public ngOnDestroy() {
        this.snackBarQueue.next([]);
        this.snackBarQueue.complete();
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public queue(message: string, configParams: SnackBarConfigParams = {}) {
        this.snackBarQueue.next(
            this.snackBarQueue.value.concat([{ message, configParams, beingDispatched: false }]),
        );
    }

    public queueInfo(message: string, title?: string) {
        const configParams: SnackBarConfigParams = {
            title: title || 'Notifications.Titles.Info',
            icon: 'info'
        };
        this.snackBarQueue.next(
            this.snackBarQueue.value.concat([{ message, configParams, beingDispatched: false }]),
        );
    }
    
    public queueError(message: string, title?: string) {
        const configParams: SnackBarConfigParams = {
            title: title || 'Notifications.Titles.Error',
            icon: 'warning'
        };
        this.snackBarQueue.next(
            this.snackBarQueue.value.concat([{ message, configParams, beingDispatched: false }]),
        );
    }

    private showSnackbar(message: string, configParams: SnackBarConfigParams = {}) {
        this.removeDismissedSnackBar(
            this.matSnackBar.openFromComponent(NotificationComponent, {
                verticalPosition: 'top',
                horizontalPosition: 'right',
                duration: 5000,
                data: {
                    message: message,
                    title: configParams.title,
                    icon: configParams.icon
                }
            }).afterDismissed()
        );
    }

    /* Remove dismissed snack bar from queue and triggers another one to appear */
    private removeDismissedSnackBar(dismissed: Observable<MatSnackBarDismiss>) {
        dismissed
            .pipe(
                // delay(500),
                take(1))
            .subscribe(() => {
                const updatedQueue = this.snackBarQueue.value;
                if (updatedQueue[0].beingDispatched) {
                    updatedQueue.shift();
                }
                this.snackBarQueue.next(updatedQueue);
            });
    }

}