import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AssetTypes } from '@dam/types';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { KeywordsService } from '../../dao/keywords.service';

@Component({
  selector: 'dam-search-assettypes-dialog',
  templateUrl: './search-assettypes-dialog.component.html',
  styleUrls: ['./search-assettypes-dialog.component.css']
})
export class SearchAssetTypesDialogComponent implements OnInit, OnDestroy {
  dataSource: string[] = Object.keys(AssetTypes).map(type => type.toLowerCase());
  displayedColumns = ['type'];

  // nameControl = new FormControl();

  // private filterSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SearchAssetTypesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string, // initial search value
    private translate: TranslateService
  ) {
    // this.filterSub = this.nameControl.valueChanges.pipe(delay(500)).subscribe(value => this.changeConditions(value));
  }

  ngOnInit() {
    // this.nameControl.setValue(this.data);
    // this.changeConditions(this.data);
  }

  ngOnDestroy() {
    // if (this.filterSub) { this.filterSub.unsubscribe(); }
  }

  close(assetType?: string) {
    this.dialogRef.close(assetType);
  }

  // private changeConditions(value: string) {
  //   const re = new RegExp(value);
  //   const conditions = {};
  //   conditions[`name.${this.translate.currentLang || 'en'}`] = { $regex: re.source, $options: 'i' };
  // }
}
