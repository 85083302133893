import { Component, OnInit } from '@angular/core';
import { DownloadRequest, User } from '@dam/types';
import { EMPTY, iif, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DownloadRequestService } from '../../dao/download-request.service';
import { AuthFacade } from '../../ngrx/auth/+state/auth.facade';

@Component({
  selector: 'dam-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  user$: Observable<User> = this.authFacade.user$;
  downloadRequests: number = 0;
  shareRequests: number = 0;
  isAdmin$: Observable<boolean> = this.authFacade.isAdmin$;
  requests$: Observable<DownloadRequest[]>;

  constructor(
    private authFacade: AuthFacade,
    private requestService: DownloadRequestService
  ) {
    this.requests$ = this.isAdmin$.pipe(
      switchMap(isAdmin => 
        iif(
          () => isAdmin,
          this.requestService.find({ status: 'pending' }).pipe(map(res => res.list)),
          EMPTY
        )
      ),
      tap(requests => {
        if (requests) {
          requests.forEach((request: DownloadRequest) => {
            if (request.isSharing) {
              this.shareRequests++;
            } else {
              this.downloadRequests++;
            }
          })
        }
      })
    );
  }

  ngOnInit() {
  }
}
