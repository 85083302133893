import { Injectable } from "@angular/core";
import { DAOService } from "./dao-service";
import {
  Folder,
  AssetTypes,
  SeasonFolderAutomatedCreationData,
} from "@dam/types";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../../../../../src/environments/environment";
import axios from "axios";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class FoldersService extends DAOService<Folder> {
  baseUrl = environment.baseUrl + "/api/folders";
  localUrl = "http://localhost:4000/api/folders";
  currentUrl: string;
  constructor(protected http: HttpClient, private router: Router) {
    super(http);
  }

  getParent(id: string): Observable<Object>{
    return this.http.get(this.baseUrl+`/getParent?id=${id}`).pipe();
  }

  createNewSeasonAutomated(
    seasonFolderData: SeasonFolderAutomatedCreationData
  ): Observable<Folder> {
    return this.http.post(
      this.baseUrl + "/autoSeasonCreation",
      seasonFolderData
    );
  }

  createMany(folders: Folder[]): Observable<Folder[]> {
    return this.http
      .post<Folder[]>(this.baseUrl + "/createMany", folders)
      .pipe(take(1));
  }

  createOne(folders: Folder[]): Observable<Folder[]> {
    return this.http
      .post<Folder[]>(this.baseUrl + "/createMany", folders)
      .pipe(take(1));
  }

  createFolder(folderName: string) {
    this.currentUrl = this.router.url;
    console.log(this.currentUrl)
    // axios
    //   .post(this.localUrl + "/createFolder", { parametro: folderName })
    //   .then((response) => {
    //     console.log(response.data); // Risposta di tipo testo
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  rename(folder) {
    return this.http.post(this.baseUrl + "/rename", folder);
  }
}
