import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '../../ngrx/auth/+state/auth.facade';

@Component({
  selector: 'dam-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent implements OnInit {
  /**
   * Router link to navigate on logout (default '/login')
   */
  @Input() navTo = '/login';
  @Input() menuItem = false;

  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) { }

  ngOnInit() {
  }

  logout() {
    this.authFacade.logout();
    this.router.navigate([this.navTo]);
  }

}
