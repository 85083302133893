import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';
import { User } from '@dam/types';

// Lookup the 'Auth' feature state managed by NgRx
const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const getIsAuthenticated = createSelector(
  getAuthState,
  (state: AuthState) => state.isAuthenticated
);

const getError = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);

const getUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

const isAdmin = createSelector(
  getUser,
  (user: User) => user && user.roles && (user.roles.includes('admin') || user.roles.includes('system'))
);

const isGuest = createSelector(
  getUser,
  (user: User) => user && user.roles && user.roles.includes('guest')
);

export const authQuery = {
  getError,
  getAuthState,
  getIsAuthenticated,
  getUser,
  isAdmin,
  isGuest
};
