import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthFacade } from './+state/auth.facade';
import { authInitialState, authReducer, AUTH_FEATURE_KEY } from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer, {
            initialState: authInitialState
        }),
        EffectsModule.forFeature([AuthEffects])
    ],
    providers: [AuthFacade]
})
export class NgrxAuthModule { }
