import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FoldersDAODataSource } from '../../datasources/folders-dao.datasource';
import { FoldersService } from '../../dao/folders.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BasicTableColumn } from '../basic-table/basic-table-column';
import { Folder } from '@dam/types';

@Component({
  selector: 'folder-search-dialog',
  templateUrl: './folder-search-dialog.component.html',
  styleUrls: ['./folder-search-dialog.component.css']
})
export class FolderSearchDialogComponent implements OnInit {
  form: FormGroup;
  columns: BasicTableColumn<Folder>[] = [
    {
      id: 'name',
      title: 'Folder.Field.Name',
      type: 'link',
      event: (event, row) => {
        event.stopPropagation();
        event.preventDefault();
        this.selectFolder(row);
      },
      value: (folder: Folder) => folder.name
    },
    {
      id: 'assetType',
      title: 'Folder.Field.Path',
      type: 'text',
      value: (folder: Folder) => folder.path
    }
  ];

  conditions = new BehaviorSubject<any>({});
  dataSource = new FoldersDAODataSource(this.foldersService);

  constructor(
    public dialogRef: MatDialogRef<FolderSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private foldersService: FoldersService
  ) {
    this.form = this.fb.group({
      name: '',
      assetType: ''
    });
  }

  ngOnInit() { }

  search() {
    const nameRe = new RegExp(this.form.value.name);
    const conditions = {
      name: { $regex: nameRe.source, $options: 'i' },
    };
    this.conditions.next(conditions);
  }

  selectFolder(folder: Folder) {
    this.dialogRef.close(folder);
  }

  close() {
    this.dialogRef.close();
  }

}
