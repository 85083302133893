import { Injectable } from '@angular/core';
import { DAOService } from './dao-service';
import { ApplicationLog } from '@dam/types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApplicationLogsService extends DAOService<ApplicationLog> {
    baseUrl = environment.baseUrl + '/api/application-logs';
    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }
}
