import { Injectable } from '@angular/core';
import { DAOService } from './dao-service';
import { DownloadRequest, Asset } from '@dam/types';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DownloadRequestService extends DAOService<DownloadRequest> {
    baseUrl = environment.baseUrl + '/api/downloadrequest';
    constructor(
        protected http: HttpClient
    ) {
        super(http)
    }

    getShareLink(assets: string[]): Observable<string> {
        return this.http.post<string>(this.baseUrl + '/link', { assets: assets }).pipe(take(1))
    }
    
    getZipData(assets: string[]): Observable<string> {
        return this.http.post<string>(this.baseUrl + '/zip', { assets: assets }).pipe(take(1))
    }

}
