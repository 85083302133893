import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login success',
  LoginError = '[Auth] Login error',
  Load = '[Auth] Load auth',
  LoadSuccess = '[Auth] Load auth success',
  LoadError = '[Auth] Load auth error',
  Logout = '[Auth] Logout',
  ResetAuth = '[Auth] Reset'
}

interface Credentials {
  email: string;
  password: string;
}

export class Load implements Action {
  readonly type = AuthActionTypes.Load;
}

export class LoadSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  /**
   * 
   * @param payload jwt token
   */
  constructor(public payload: string) { }
}

export class LoadError implements Action {
  readonly type = AuthActionTypes.LoadError;

  /**
   * 
   * @param payload error
   */
  constructor(public payload: any) {}
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Credentials) { }
}

export class LoginError implements Action {
  readonly type = AuthActionTypes.LoginError;

  /**
   * 
   * @param payload error
   */
  constructor(public payload: any) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  /**
   * 
   * @param payload jwt token
   */
  constructor(public payload: string) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class ResetAuth implements Action {
  readonly type = AuthActionTypes.ResetAuth;
}

export type AuthAction = 
  Login | LoginSuccess | LoginError | Load | LoadSuccess | LoadError | Logout | ResetAuth;

export const fromAuthActions = {
  Login,
  LoginSuccess,
  LoginError,
  Load,
  LoadSuccess,
  LoadError,
  Logout,
  ResetAuth,
};
