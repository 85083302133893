import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AuthPartialState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import { Login, Logout, Load } from './auth.actions';

@Injectable()
export class AuthFacade {

  constructor(private store: Store<AuthPartialState>) {}

  auth$ = this.store.pipe(select(authQuery.getAuthState));
  isAuthenticated$ = this.store.pipe(select(authQuery.getIsAuthenticated));
  error$ = this.store.pipe(select(authQuery.getError));
  user$ = this.store.pipe(select(authQuery.getUser));
  isAdmin$ = this.store.pipe(select(authQuery.isAdmin));
  isGuest$ = this.store.pipe(select(authQuery.isGuest));

  load() {
    this.store.dispatch(new Load());
  }

  login(credentials) {
    this.store.dispatch(new Login(credentials));
  }
  
  logout() {
    this.store.dispatch(new Logout());
  }

}
