import { APIFindOptions, ApiQueryResponse, Folder } from '@dam/types';
import { Observable } from 'rxjs';

import { DAOService } from '../dao/dao-service';
import { DAODataSource } from './dao-data-source';

export class FoldersDAODataSource extends DAODataSource<Folder> {
  constructor(dao: DAOService<Folder>) {
    super(dao);
  }

  protected find(conditions: any, options: APIFindOptions): Observable<ApiQueryResponse<Folder>> {
    options.populate ? options.populate.push('createdBy') : options.populate = ['createdBy'];
    return this.dao.find(conditions, options);
  }
}
