import { Component, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthFacade } from '@dam/dam/core';

@Component({
  selector: 'digital-asset-manager-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  authenticated$: Observable<boolean> = this.auth.isAuthenticated$;
  authUser$: Observable<any> = this.auth.user$;
  mobileQuery: MediaQueryList;
  data: any;
  private _mobileQueryListener: () => void;

  constructor(
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private auth: AuthFacade
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  getData(evt) {
    this.data = evt;
  }

}
