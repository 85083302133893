import { Keyword, APIFindOptions, ApiQueryResponse } from '@dam/types';

import { DAOService } from '../dao/dao-service';
import { DAODataSource } from './dao-data-source';
import { Observable } from 'rxjs';

export class KeywordsDAODataSource extends DAODataSource<Keyword> {
  constructor(dao: DAOService<Keyword>) {
    super(dao);
  }

  protected find(conditions: any, options: APIFindOptions): Observable<ApiQueryResponse<Keyword>> {
    options.populate ? options.populate.push('createdBy') : options.populate = ['createdBy'];
    return this.dao.find(conditions, options);
  }
}
