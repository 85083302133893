import { Injectable } from '@angular/core';
import { DAOService } from './dao-service';
import { Asset } from '@dam/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AssetsService extends DAOService<Asset> {
  baseUrl = environment.baseUrl + '/api/assets';
  constructor(
    protected http: HttpClient
  ) {
    super(http)
  }

  // differ from getImageData() : this method create a downloaded
  download(asset: Asset): Observable<any> {
    return this.http.post(`${this.baseUrl}/download`, { asset: asset, path: asset.path, filename: asset.name }).pipe(take(1))
  }

  //returns file data:
  getImageData(path, name): Observable<any> {
    return this.http.post(`${this.baseUrl}/data`, { path: path, filename: name }).pipe(take(1));
  }

  upload(formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, formData, { reportProgress: true, observe: 'events' });
  }

  create(assets: Asset[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/create`, assets);
  }

  moveMany(ids: string[], oldFolderId: string, newFolderId: string): Observable<any> {
    const obj = {
      ids: ids,
      oldFolder: oldFolderId,
      newFolder: newFolderId
    }
    return this.http.post(this.baseUrl + '/moveMany', obj)
  }

}
