import { User } from './user.type';
import { AssetTypes } from './asset-types.enum';

export class Folder {
    _id?: string;
    name?: string;
    newName?: string; //used for rename
    description?: string;
    parent?: string | Folder;
    path?: string;
    assetType?: string;
    createdBy?: string | User;
    createdAt?: string;
}

export interface SeasonFolderAutomatedCreationData {
    name?: string;
    folderBasePath?: string;
    assetTypes: AssetTypes[]
}