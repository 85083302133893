import { Component, Input, OnInit } from '@angular/core';
import { User, SystemConfig } from '@dam/types';
import { Observable, of, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { AuthFacade } from '../../ngrx/auth/+state/auth.facade';
import { SystemConfigService } from '../../dao/system-config.service';

export class HeaderLink {
  title: string;
  url?: string;
  external?: boolean;
  public?: boolean;
  visible$?: Observable<boolean> = of(true);

  constructor(conf: HeaderLink) {
    this.title = conf.title;
    this.url = conf.url;
    this.external = conf.external;
    this.public = conf.public;
    if (conf.visible$) {
      this.visible$ = conf.visible$.pipe(startWith(true));
    }
  }
}

@Component({
  selector: 'dam-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  @Input() color = 'primary';

  user$: Observable<User> = this.authFacade.user$;
  isAdmin$ = this.authFacade.isAdmin$;
  authSub: Subscription;
  userId: string;
  isGuest$: Observable<boolean> = this.authFacade.isGuest$;

  constructor(
    private authFacade: AuthFacade) {
  }

  ngOnInit() {
    this.authSub = this.authFacade.user$.subscribe(user => {
      if (user) {
        this.userId = user._id;
      }
    });
  }
}
