import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { APIFindOptions, ApiQueryResponse } from "@dam/types";
import { MatOptionSelectionChange } from "@angular/material";

export abstract class DAOService<T> {
  baseUrl: string;
  constructor(protected http: HttpClient) {}

  find(
    conditions?: any,
    options: APIFindOptions = {}
  ): Observable<ApiQueryResponse<T>> {
    let params = new HttpParams();
    if (options.sort && options.sort.active) {
      params = params.append("sortBy", options.sort.active);
      params = params.append(
        "sortDirection",
        options.sort.direction === "asc" ? "1" : "-1"
      );
    }
    if (options.limit) {
      params = params.append("limit", options.limit.toString());
    }
    if (options.skip) {
      params = params.append("skip", options.skip.toString());
    }
    if (options.populate) {
      params = params.append("populate", options.populate);
    }
    console.log(conditions);
    console.log(params);
    return this.http.post(this.baseUrl + "/search", conditions, {
      params: params,
    });
  }

  findOneBy(conditions?: any) {
    return this.http
      .post<T>(this.baseUrl + "/findoneby", conditions)
      .pipe(take(1));
  }

  findOne(id: any): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  create(obj: any) {
    return this.http.post<T>(`${this.baseUrl}`, obj).pipe(take(1));
  }

  update(obj: any) {
    return this.http.put<T>(`${this.baseUrl}/${obj._id}`, obj).pipe(take(1));
  }

  save(obj: any) {
    return obj._id ? this.update(obj) : this.create(obj);
  }

  delete(id: string): Observable<any> {
    return this.deleteMany([id]);
  }

  deleteMany(ids: any[]): Observable<any> {
    return this.http.post(this.baseUrl + "/deleteMany", ids);
  }

  moveMany(ids: [], oldFolderId: string, newFolderId: string): Observable<any> {
    const obj = {
      ids: ids,
      oldFolder: oldFolderId,
      newFolderId: newFolderId,
    };
    return this.http.post(this.baseUrl + "moveMany", obj);
  }
}
