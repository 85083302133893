import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { APIFindOptions } from '@dam/types';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge, Observable, of, Subscription } from 'rxjs';

import { DAMDataSource } from '../../../datasources/data-source.interface';
import { BasicTableColumn } from '../basic-table-column';

export class AbstractTableComponent<T>
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() selectable = true;
  @Input() dataSource: DAMDataSource<T>;
  @Input() columns: BasicTableColumn<T>[] = [];

  @Input() pageSize = 5;
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() projections: string[] = []; // like mongo populate...
  @Input() activeSort: string;
  @Input() sortDirection: 'asc' | 'desc' = 'desc';
  @Input() conditions = new BehaviorSubject<any>({});
  @Input() selection: SelectionModel<T> = new SelectionModel<T>(true, []);

  @Output() selectionChanged = new EventEmitter<SelectionModel<T>>();
  @Output() rowClicked = new EventEmitter<T>();

  displayedColumns: string[] = [];

  private sub: Subscription;

  constructor(protected translate: TranslateService) { }

  ngOnInit() {
    this.displayedColumns = (this.columns || []).map(col => col.id);
    if (this.selectable) {
      this.displayedColumns.reverse().push('select');
      this.displayedColumns.reverse();
    }
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageSizeOptions = this.pageSizeOptions;
    this.dataSource.selection = this.selection;
  }

  ngAfterViewInit() {
    this.sub = merge(
      this.paginator.page,
      this.sort.sortChange,
      this.conditions
    ).subscribe(() => {
      this.loadPage();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  loadPage() {
    const conditions = this.conditions.value;
    const options: APIFindOptions = {
      skip: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
      sort: {
        active: this.sort.active,
        direction: this.sort.direction
      },
      populate: this.projections
    };
    this.dataSource.load(conditions, options);
  }

  refresh() {
    // this.paginator.firstPage();
  }

  search(conditions) {
    // this.conditionsChange.next(conditions);
  }

  // SELECTION
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(customer => {
        this.selection.select(customer);
      });
  }

  getColumnTitle(colId: string): Observable<string> {
    try {
      const title = this.columns.find(col => col.id === colId).title;
      return this.translate.get(title);
    } catch (error) {
      console.log('error', error);
      return of('');
    }
  }

  onRowClicked(row) {
    this.rowClicked.next(row);
  }
}
