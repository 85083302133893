import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginComponent, DashboardComponent } from '@dam/dam/core';


const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    {
        // admin users
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/users').then(module => module.DamUsersModule)
    },
    {
        // admin folders
        path: 'folders',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/folders').then(module => module.DamFoldersModule)
    },
    {
        // admin keywords
        path: 'keywords',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/keywords').then(module => module.DamKeywordsModule)
    },
    {
        // contents
        path: 'contents',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/contents').then(module => module.DamContentsModule)
    },
    {
        path: 'requests',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/requests').then(module => module.DamRequestsModule)
    },
    {
        path: 'system-configuration',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/system-configuration').then(module => module.DamSystemConfigurationModule)
    },
    {
        // analytics
        path: 'analytics',
        canActivate: [AuthGuard],
        loadChildren: () => import('@dam/dam/analytics').then(module => module.DamAnalyticsModule)
    },
    {
        // External link
        path: 'guest',
        loadChildren: () => import('@dam/dam/guest').then(module => module.DamGuestModule)
    },
    {
        // External link
        path: 'change-password',
        loadChildren: () => import('@dam/dam/change-password').then(module => module.DamChangePasswordModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}