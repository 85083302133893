import { User } from './user.type';
import { DownloadRequest } from './download-request.type';

export class Asset {
    _id?: string;
    name?: string;
    oldname?: string;
    assetType?: string;
    path?: string;
    data?: any;
    keywords?: string[];
    tags?: string[];
    kind?: string;
    format?: string;
    size?: number;
    description?: string;
    thumbnail?: string;
    width?: number;
    height?: number;
    requests?: (DownloadRequest | string)[];
    folder?: string;
    version?: number;
    previous?: Asset | string;
    availableVersions?: number;
    copyrightText?: string;
    copyrightExpiryDate?: Date;
    createdBy?: string | User;
    createdAt?: string;
}
