import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { map, take } from 'rxjs/operators';

import { AuthActionTypes, LoadError, LoadSuccess, Login, LoginError, LoginSuccess, Logout, ResetAuth } from './auth.actions';
import { AuthPartialState } from './auth.reducer';
import { environment } from '../../../../../../../../src/environments/environment';

@Injectable()
export class AuthEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private dataPersistence: DataPersistence<AuthPartialState>,
  ) { }

  @Effect()
  login$ = this.dataPersistence.fetch(AuthActionTypes.Login, {
    run: (action: Login, state: AuthPartialState) => {
      localStorage.removeItem('dam-token');
      return this.http.post<{token: string}>(environment.baseUrl + '/auth/signin', action.payload).pipe(
        take(1),
        map(res => {
          if (res && res.token) {
            localStorage.setItem('dam-token', res.token);
            return new LoginSuccess(res.token);
          } else {
            return new LoginError(new Error('Unable to login: no token received!'));
          }
        })
      );
    },
    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoginError(error);
    }
  });

  @Effect()
  load$ = this.dataPersistence.fetch(AuthActionTypes.Load, {
    run: (action: Login, state: AuthPartialState) => {
      try {
        const token = localStorage.getItem('dam-token');
        if (token) {
          return new LoadSuccess(token);
        } else {
          return new ResetAuth();
        }
      } catch (error) {
        return new LoadError(error)
      }
    },

    onError: (action: Login, error) => {
      console.log('Error', error);
      return new LoadError(error);
    }
  });

  @Effect()
  logout$ = this.dataPersistence.fetch(AuthActionTypes.Logout, {
    run: (action: Logout, state: AuthPartialState) => {
      localStorage.removeItem('dam-token');
      return new ResetAuth();
    },
    onError: (action: Logout, error) => {
      console.log('Lgout error', error);
    }
  });

}
