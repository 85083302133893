import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractTableComponent } from './abstract/abstract-table-component';

@Component({
  selector: 'dam-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent extends AbstractTableComponent<any> {
  constructor(translate: TranslateService) {
    super(translate);
  }
}
