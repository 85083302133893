import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacade } from '../ngrx/auth/+state/auth.facade';
import { AuthState } from '../ngrx/auth/+state/auth.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authFacade.auth$.pipe(
      map((auth: AuthState) => {
        if (auth.user && auth.user.roles.includes('guest')) {
          if (
            (next.url.length > 0 && next.url[0].path === 'guest') ||
            (next.parent.url.length > 0 && next.parent.url[0].path === 'guest')
          ) {
            return true;
          } else {
            this.router.navigate(['/guest']);
            return false;
          }
        }
        if (!auth.isAuthenticated) {
          this.router.navigate(['/login']);
        } else {
          if (auth.user && !!auth.user.needPasswordChange) {
            this.router.navigate([`/change-password/auth`]);
            return false;
          }
        }
        return auth.isAuthenticated;
      })
    );
  }
}
